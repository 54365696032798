<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Pengaturan Filter
            </div>
            <div class="card-body row">
                <div class="col-md-5 form-group">
                    <label class="control-label">Nama</label>
                    <input type="hidden" name="row_id" value="" />
                    <input id="name" class="form-control name" v-model="form.name" type="text" required="required" name="name" />
                </div>
                <div class="col-md-4 form-group">
                    <label class="control-label">Kelas</label>
                    <select v-model="form.kelas" class="form-control kelas">
                        <option v-for="kelas in optKelas" v-bind:value="kelas.id">{{ kelas.label }}</option>
                    </select>
                </div>
                <div class="col-md-4 form-group">
                    <label class="control-label">Program</label>
                    <select v-model="form.program" class="form-control program">
                        <option v-for="program in optProgram" v-bind:value="program.id">{{ program.label }}</option>
                    </select>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>NO.</th>
                    <th>NAMA - NO. INDUK</th>
                    <th>PROGRAM</th>
                    <th>KELAS</th>
                    <th>JK</th>
                    <th>EMAIL</th>
                    <th>TELP</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Nama - No Induk</label>
                                    <input type="hidden" name="row_id" value="" />
                                    <v-select :options="optSiswa" v-model="form.siswa_id" :reduce="opt => opt.id" />
                                </div>
                                <!-- <div class="col-md-6 form-group">
                                    <label class="control-label">Program</label>
                                    <v-select :options="optProgram" v-model="form.program_id" :reduce="opt => opt.id" />
                                </div> -->
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Kelas</label>
                                    <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
    name: 'Userlist',
    components: {
        vSelect,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            optKelas: [],
            optProgram: [],
            optSiswa: [],
            optBranch: [],
            formTitle: 'Tambah',
            form: {
                name: '',
                kelas: '',
                program: '',
                email: '',
                gender: '',
                siswa_id: '',
                kelas_id: '',
                program_id: ''
            }
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        //console.log('load initial data')
        authFetch('/akademik/pembagian_kelas/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
            })

        authFetch('/akademik/pembagian_kelas/program')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optProgram = js.data;
            })

        authFetch('/akademik/pembagian_kelas/siswa')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optSiswa = js.data;
            })
        authFetch('/akademik/pembagian_kelas/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBranch = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        search: function() {
            this.table.api().ajax.reload();
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/akademik/pembagian_kelas';
            if (this.method == 'PUT') urlSubmit = '/akademik/pembagian_kelas/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List Pembagian Kelas",
            "roles": this.$route.params.roles,
            "ajax": "/akademik/pembagian_kelas",
            "columns": [
                { "data": "rownum" },
                { "data": "display_name" },
                { "data": "program" },
                { "data": "nama_kelas" },
                { "data": "gender" },
                { "data": "email" },
                { "data": "no_hp" },
            ],
            filterBy: [1],
            filter:false,
            "rowCallback": function(row, data, displayNum, displayIndex, dataIndex ) {
                $('td:eq(0)', row).html(displayNum+1);
            },
            "paramData": function ( d ) {
                d.nama = $('.name').val();
                d.kelas = $('.kelas').val();
                d.program = $('.program').val();
                console.log(d.nama+' '+d.kelas+' '+d.program);
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.form.gender = evt.data.gender;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/akademik/pembagian_kelas/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                    filename: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                    filename: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                    filename: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                    filename: function(){
                        return 'Rekap Pembagian Kelas';
                    },
                },
            ],
        });
    }
}
</script>